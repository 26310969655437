.seats-type {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 32px;
	&__item {
		display: flex;
		align-items: center;
		color: #8d8f93;
		font-size: 18px;
		line-height: 1.25;
		text-transform: lowercase;
		& + & {
			margin-left: 8px;
		}
		&:before {
			content: "";
			box-sizing: border-box;
			border-radius: 7px;
			display: block;
			width: 24px;
			height: 24px;
			margin-right: .25em;
		}
		&:nth-of-type(1):before {
			border: 2px solid #d0d0d0;
		}
		&:nth-of-type(2):before {
			background-color: #6cd43e;
		}
		&:nth-of-type(3):before {
			background-color: #f8c90f;
		}
	}
}
