@media (min-width: 768px) {
  .hide-desktop {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .hide-mobile {
    display: none !important;
  }
}

.alert {
  display: flex;
  align-items: center;
  font-size: 16px;
  position: relative;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid transparent;
  border-radius: var(--custom-border_radius, 6px);
}
.alert--warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert--danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert--info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert__icon {
  display: block;
  width: 1em;
  flex: 0 0 1em;
  height: 1em;
  margin-right: 0.5em;
}
.alert__content {
  width: 100%;
  flex: 1 0 0%;
}

.sk-fading-circle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.nf-route__btn {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

.nf-tabs-form__group input[type=time], .nf-tabs-form__group input[type=text],
.nf-tabs-form__group input[type=date], .nf-tabs-form__group select {
  border-radius: 3px;
  width: 100%;
  line-height: 43px;
  height: 45px;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border: 1px solid currentColor;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.backg {
  background: var(--custom-background_color, rgba(85, 93, 105, 0.9803921569));
  border-radius: var(--custom-border_radius, 10px);
  padding: 16px;
  color: var(--custom-font_color, #fff);
}

.nf-dates {
  overflow: hidden;
  padding-bottom: 6px;
  margin-bottom: 10px;
}
@media (max-width: 767.98px) {
  .nf-dates {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.nf-dates-carousel {
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.nf-dates-carousel .swiper-slide {
  display: flex;
  align-items: flex-end;
  height: auto;
}
.nf-dates-carousel .swiper-button-prev,
.nf-dates-carousel .swiper-button-next {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e0e1e3;
  width: 26px;
  height: 52px;
  bottom: 8px;
  z-index: 2;
  border-bottom-right-radius: 52px;
  border-top-right-radius: 52px;
}
@media (max-width: 767.98px) {
  .nf-dates-carousel .swiper-button-prev,
  .nf-dates-carousel .swiper-button-next {
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 36px;
    bottom: auto;
    border-bottom-right-radius: 36px;
    border-top-right-radius: 36px;
  }
}
.nf-dates-carousel .swiper-button-prev.swiper-button-disabled,
.nf-dates-carousel .swiper-button-next.swiper-button-disabled {
  opacity: 0.2;
  pointer-events: none;
}
.nf-dates-carousel .swiper-button-prev svg,
.nf-dates-carousel .swiper-button-next svg {
  display: block;
  width: 16px;
  height: 16px;
}
.nf-dates-carousel .swiper-button-prev svg path,
.nf-dates-carousel .swiper-button-next svg path {
  fill: #166bc8;
}
.nf-dates-carousel .swiper-button-prev {
  left: 0;
}
.nf-dates-carousel .swiper-button-next {
  transform: scaleX(-1);
  right: 0;
}
@media (max-width: 767.98px) {
  .nf-dates-carousel .swiper-button-next {
    transform: translateY(-50%) scaleX(-1);
  }
}
.nf-dates-item {
  cursor: pointer;
  width: 100%;
  flex: 1 0 0%;
  text-align: center;
  line-height: 1.25;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .nf-dates-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 125px;
    flex: 0 0 125px;
    height: 56px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.nf-dates-item + .nf-dates-item {
  border-left: 1px solid #f4f4f4;
}
.nf-dates-item:not(.is-disabled):hover {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.nf-dates-item.is-disabled {
  pointer-events: none;
}
.nf-dates-item.is-active {
  pointer-events: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-bottom: 4px solid #f1c933;
  position: relative;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .nf-dates-item.is-active {
    box-shadow: none;
  }
}
.nf-dates-item__date {
  font-size: 14px;
  font-weight: 700;
  color: #166bc8;
}
@media (max-width: 767.98px) {
  .nf-dates-item__date {
    font-size: 12px;
    font-weight: 400;
  }
}
.nf-dates-item.is-active .nf-dates-item__date {
  font-size: 20px;
}
@media (max-width: 767.98px) {
  .nf-dates-item.is-active .nf-dates-item__date {
    font-size: 12px;
    font-weight: 700;
  }
}
.nf-dates-item.is-disabled .nf-dates-item__date {
  color: #c3c7cc;
}
.nf-dates-item__day {
  font-size: 12px;
  color: #6c7079;
}
@media (max-width: 767.98px) {
  .nf-dates-item__day {
    order: -1;
    margin-right: 0.25em;
    color: #166bc8;
  }
}
.nf-dates-item.is-active .nf-dates-item__day {
  font-size: 14px;
}
@media (max-width: 767.98px) {
  .nf-dates-item.is-active .nf-dates-item__day {
    font-size: 12px;
    font-weight: 700;
  }
}
@media (max-width: 767.98px) {
  .nf-dates-item.is-disabled .nf-dates-item__day {
    color: #c3c7cc;
  }
}
.nf-dates-item__price {
  font-size: 20px;
  font-weight: 700;
  color: #073590;
}
@media (max-width: 767.98px) {
  .nf-dates-item__price {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    flex: 0 0 100%;
  }
}
.nf-dates-item.is-active .nf-dates-item__price {
  font-size: 30px;
  line-height: 1;
}
@media (max-width: 767.98px) {
  .nf-dates-item.is-active .nf-dates-item__price {
    font-size: 18px;
    font-weight: 700;
  }
}
@media (max-width: 767.98px) {
  .nf-dates-item__disable {
    display: flex;
    justify-content: center;
    width: 100%;
    flex: 0 0 100%;
  }
}
.nf-dates-item__disable-icon {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin: 3.5px 0;
}
@media (max-width: 767.98px) {
  .nf-dates-item__disable-icon {
    width: 14px;
    height: 14px;
    margin: 1.75px 0;
  }
}

.scheduleBlockWrapper {
  position: relative;
  min-height: 40px;
}
.scheduleBlockWrapper.is-loading .scheduleBlock {
  position: relative;
}
.scheduleBlockWrapper.is-loading .scheduleBlock:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
}
@media (min-width: 768px) {
  .scheduleBlockWrapper.is-loading .scheduleBlock:before {
    right: 16px;
    left: 16px;
  }
}
.scheduleBlockWrapper.is-loading .scheduleBlock:not(:empty) + .sk-fading-circle {
  top: 135px;
}
@media (min-width: 768px) {
  .scheduleBlockWrapper.is-loading .scheduleBlock:not(:empty) + .sk-fading-circle {
    top: 185px;
  }
}
.scheduleBlockWrapper.is-loading .sk-fading-circle {
  display: block !important;
  z-index: 3;
}

.nf-route {
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--custom-border_radius, 10px);
  margin-top: 18px;
  margin-bottom: 24px;
}
.nf-route.is-disabled {
  opacity: 0.5;
}
.nf-route.is-individual {
  background-image: url("../images/taxiBg.png");
  background-size: 32px;
  background-position: center;
}
.nf-route__container {
  padding: 15px 25px 15px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 365px) {
  .nf-route__container {
    padding: 5px 10px 5px;
  }
}
.nf-route__from, .nf-route__to {
  display: flex;
  flex-direction: column;
}
.nf-route__main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.nf-route__content {
  text-align: center;
  padding: 0 20px;
  width: 100%;
}
.nf-route__line {
  width: 100%;
  height: 2px;
  background-color: var(--custom-primary_color, #73b3ff);
}
.nf-route__bus {
  width: 28px;
  flex: 0 0 28px;
  height: 17px;
  margin-right: 16px;
  margin-left: 16px;
  color: var(--custom-primary_color, #73b3ff);
}
.is-individual .nf-route__bus {
  height: 28px;
}
.nf-route__time {
  margin-bottom: 5px;
  text-align: center;
  color: #000;
  font-size: 30px;
}
@media screen and (max-width: 345px) {
  .nf-route__time {
    font-size: 25px;
  }
}
.nf-route__place {
  color: #8a8a8a;
  font-size: 18px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .nf-route__place {
    font-size: 12px;
  }
}
.nf-route__duration {
  font-size: 14px;
  color: #000;
}
.nf-route__footer {
  position: relative;
  min-height: 50px;
  border-top: 2px dashed var(--custom-primary_color, #73b3ff);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #d9ebff;
  border-radius: 0 0 var(--custom-border_radius, 5px) var(--custom-border_radius, 5px);
}
.nf-route__footer .busRow {
  display: none;
  width: 100%;
  flex: 0 0 100%;
  padding-top: 32px;
  padding-bottom: 16px;
}
.nf-route__footer .amenities {
  display: none;
}
.nf-route__price {
  color: var(--custom-primary_color, #73b3ff);
  font-weight: 700;
  font-size: 30px;
}
.nf-route__price-text {
  color: #8a8a8a;
  font-size: 14px;
}
.nf-route__btn {
  cursor: pointer;
  position: absolute !important;
  padding-right: 10px;
  padding-top: 13px;
  display: flex;
  justify-content: flex-end;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.nf-route__arrow {
  display: block;
  color: var(--custom-primary_color, #bedcff) !important;
  width: 30px;
  flex: 0 0 30px;
  height: 30px;
}
.nf-route__currency {
  font-size: 12px;
  margin-left: 5px;
}
.nf-route__date {
  margin-top: 5px;
  color: #000;
  font-size: 14px;
  text-align: center;
}

.seats-type {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}
.seats-type__item {
  display: flex;
  align-items: center;
  color: #8d8f93;
  font-size: 18px;
  line-height: 1.25;
  text-transform: lowercase;
}
.seats-type__item + .seats-type__item {
  margin-left: 8px;
}
.seats-type__item:before {
  content: "";
  box-sizing: border-box;
  border-radius: 7px;
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 0.25em;
}
.seats-type__item:nth-of-type(1):before {
  border: 2px solid #d0d0d0;
}
.seats-type__item:nth-of-type(2):before {
  background-color: #6cd43e;
}
.seats-type__item:nth-of-type(3):before {
  background-color: #f8c90f;
}

.continue-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.continue-block__pleaceNum {
  font-size: 18px;
  color: #8d8f93;
}
.continue-block__btn {
  background-color: var(--custom-button_color, seagreen);
  border-radius: var(--custom-border_radius, 10px);
  color: var(--custom-font_color, #fff);
  font-family: raleway, sans-serif;
  font-size: var(--custom-font_size, 18px);
  font-weight: 700;
  line-height: 48px;
  padding: 0 24px;
  text-transform: uppercase;
  cursor: pointer;
}
* + .continue-block__btn {
  margin-left: 16px;
}

.nf-tabs-control {
  display: flex;
  margin-bottom: 4px;
}
@media (max-width: 767.98px) {
  .nf-tabs-control {
    display: none;
  }
}
.nf-tabs-control__item {
  cursor: pointer;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: var(--custom-font_size);
  color: var(--custom-font_color);
  background-color: var(--custom-add-background_color, #ccc);
  border-radius: var(--custom-border_radius);
}
.nf-tabs-control__item.is-active {
  position: relative;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--custom-background_color);
}
.nf-tabs-control__item.is-active:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 4px;
  background-color: var(--custom-background_color, currentColor);
}
.nf-tabs-control__item + .nf-tabs-control__item {
  margin-left: 4px;
}
.nf-tabs__container {
  color: var(--custom-font_color, #fff);
  border-radius: 0 var(--custom-border_radius, 3px) var(--custom-border_radius, 3px) var(--custom-border_radius, 3px);
}
@media (max-width: 767.98px) {
  .nf-tabs__container {
    border-radius: var(--custom-border_radius);
  }
}
@media (min-width: 768px) {
  .nf-tabs__container {
    background-color: var(--custom-background_color);
  }
}
.nf-tabs-item {
  display: none;
}
@media (max-width: 767.98px) {
  .nf-tabs-item {
    background-color: var(--custom-add-background_color);
  }
}
.nf-tabs-item-title {
  position: relative;
  padding: 12px;
  font-weight: 700;
  font-size: var(--custom-font_size);
  color: var(--custom-font_color);
}
@media (max-width: 767.98px) {
  .nf-tabs-item-title {
    background-color: var(--custom-background_color);
  }
  .nf-tabs-item-title[data-id=booking] {
    border-top-left-radius: var(--custom-border_radius);
    border-top-right-radius: var(--custom-border_radius);
  }
  .nf-tabs-item-title[data-id=status-booking]:not(.is-active) {
    border-bottom-left-radius: var(--custom-border_radius);
    border-bottom-right-radius: var(--custom-border_radius);
  }
}
@media (min-width: 768px) {
  .nf-tabs-item-title {
    display: none;
  }
}
.nf-tabs-item-title:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: url("../images/arrow-down.png") center no-repeat;
  background-size: contain;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  transition: 0.25s transform;
}
@media (max-width: 767.98px) {
  .nf-tabs-item-title.is-active {
    text-align: center;
    font-size: 20px;
  }
  .nf-tabs-item-title.is-active + .nf-tabs-item[data-id=status-booking] {
    border-bottom-left-radius: var(--custom-border_radius);
    border-bottom-right-radius: var(--custom-border_radius);
  }
}
.nf-tabs-item-title.is-active:after {
  transform: translateY(-50%) rotate(180deg);
}
.nf-tabs-item + .nf-tabs-item-title {
  border-top: 1px solid var(--custom-font_color, #fff);
}
.nf-tabs-form {
  position: relative;
  width: 100%;
  flex: 0 0 100%;
}
.nf-tabs-form__row--status {
  display: none !important;
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--custom-background_color);
  margin-bottom: 0;
  align-items: center;
  top: -15px;
  bottom: -15px;
  border-radius: 0 var(--custom-border_radius) var(--custom-border_radius) var(--custom-border_radius);
}
@media (max-width: 767.98px) {
  .nf-tabs-form__row--status {
    left: -12px;
    right: -12px;
    padding: 15px 12px;
    border-radius: 0 0 var(--custom-border_radius) var(--custom-border_radius);
  }
}
.nf-tabs-form__row--status.is-show {
  display: flex !important;
}
@media (max-width: 767.98px) {
  .nf-tabs-form__row--status.is-show {
    display: block !important;
  }
}
.nf-tabs-form__row--status:after {
  cursor: pointer;
  content: "";
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 12px;
  height: 12px;
  background: url("../images/close.png") center no-repeat;
  background-size: contain;
}
.nf-tabs-form__group {
  position: relative;
  font-size: var(--custom-font_size);
  color: var(--custom-font_color);
}
.nf-tabs-form__group--flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.nf-tabs-form__group + .nf-tabs-form__group {
  margin-top: 15px;
}
.nf-tabs-form__group select {
  border-radius: var(--custom-border_radius);
}
.nf-tabs-form__group select option {
  background-color: var(--custom-background_color);
}
.nf-tabs-form__group input[type=text],
.nf-tabs-form__group input[type=date] {
  border-radius: var(--custom-border_radius);
  outline: none;
}
.nf-tabs-form__group input[type=date],
.nf-tabs-form__group input[type=time] {
  border-radius: var(--custom-border_radius);
  position: relative;
}
.nf-tabs-form__group input[type=date]::-webkit-calendar-picker-indicator,
.nf-tabs-form__group input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
}
.nf-tabs-form__group input[type=date]:focus,
.nf-tabs-form__group input[type=time]:focus {
  outline: none;
}
.nf-tabs-form__group table {
  border-collapse: collapse;
  line-height: 22.5px;
}
.nf-tabs-form__checkbox {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.nf-tabs-form__checkbox:checked ~ .nf-tabs-form__date .forTime {
  display: block;
}
.nf-tabs-form__checkbox:checked + .nf-tabs-form__label:before {
  transform: translate(-5px, -50%);
  background-color: var(--custom-button_color);
}
.nf-tabs-form__checkbox + .nf-tabs-form__label {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}
.nf-tabs-form__checkbox + .nf-tabs-form__label:before, .nf-tabs-form__checkbox + .nf-tabs-form__label:after {
  content: "";
  display: block;
}
.nf-tabs-form__checkbox + .nf-tabs-form__label:after {
  border-radius: 12px;
  height: 24px;
  width: 40px;
  flex: 0 0 40px;
  border: 1px solid var(--custom-font_color);
  margin-left: 12px;
}
.nf-tabs-form__checkbox + .nf-tabs-form__label:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--custom-font_color);
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06);
  width: 18px;
  height: 18px;
  right: 0;
  transform: translate(-19px, -50%);
  transition: transform 0.25s;
}
.nf-tabs-form__checkbox ~ .nf-tabs-form__date .forTime {
  display: none;
}
.nf-tabs-form__label {
  display: block;
  margin: 0;
  font-size: var(--custom-font_size);
  color: var(--custom-font_color);
}
.nf-tabs-form-places {
  display: flex;
  align-items: center;
  position: relative;
}
.nf-tabs-form-places__input {
  display: block;
  width: 36px !important;
  flex: 0 0 36px;
  text-align: center;
  padding: 0;
  margin: 0;
  border: none !important;
}
.nf-tabs-form-places__control {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: var(--custom-border_radius);
  color: var(--custom-font_color, #fff);
  border: 1px solid currentColor;
  width: 37px;
  flex: 0 0 37px;
  height: 39px;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
.nf-tabs-form-places__control[disabled] {
  opacity: 0.3;
}
.nf-tabs-form-places__control:before {
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1;
}
.nf-tabs-form-places__control[data-func=dec] {
  margin-left: 6px;
}
.nf-tabs-form-places__control[data-func=dec]:before {
  content: "-";
}
.nf-tabs-form-places__control[data-func=inc]:before {
  content: "+";
}
.nf-tabs-form__exchange {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--custom-button_color);
  color: var(--custom-font_color_authorization_buttons);
  width: 56px;
  flex: 0 0 56px;
  height: 44px;
  border: none;
  outline: none;
  border-radius: var(--custom-border_radius);
  z-index: 2;
}
.nf-tabs-form__exchange svg {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
}
.nf-tabs-form__date {
  display: flex;
  align-items: center;
  margin-top: 15px;
  border-radius: var(--custom-border_radius);
  border: 1px solid var(--custom-font_color);
  width: 100%;
  flex: 0 0 100%;
  min-height: 45px;
}
.nf-tabs-form__date .forDate {
  position: relative;
  width: 100%;
  flex: 1 0 0%;
}
.nf-tabs-form__date .forDate .additImageBlock {
  right: auto;
  left: 0;
  padding-right: 0;
  padding-left: 10px;
}
.nf-tabs-form__date .forTime {
  position: relative;
  width: 50%;
  flex: 0 0 50%;
}
.nf-tabs-form__date .forTime .additImageBlock {
  right: 0px;
}
.nf-tabs-form__date .additImageBlock {
  color: var(--custom-font_color);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nf-tabs-form__date .additImageBlock .icon {
  display: block;
  width: 20px;
  height: 20px;
}
.nf-tabs-form__date input[type=text],
.nf-tabs-form__date input[type=date],
.nf-tabs-form__date input[type=time] {
  border: none;
  border-radius: 0;
}
.nf-tabs-form__date input[type=time] {
  text-align: right;
  padding-right: 40px;
}
.nf-tabs-form__date input[type=text],
.nf-tabs-form__date input[type=date] {
  padding-left: 40px;
}
.nf-tabs-status {
  display: flex;
  width: 100%;
}
.nf-tabs-status__item {
  border: 1px solid var(--custom-font_color, #fff);
  border-radius: 3px;
  width: 100%;
  flex: 1 0 0%;
  padding: 20px;
  text-align: center;
}
.nf-tabs-status__item + .nf-tabs-status__item {
  margin-left: 12px;
}
.nf-tabs-submit {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .nf-tabs-submit {
    display: flex;
    justify-content: center;
  }
}