.continue-block {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
	&__pleaceNum {
		font-size: 18px;
		color: #8d8f93;
	}
	&__btn {
		background-color: custom(button_color, seagreen);
		border-radius: custom(border_radius,10px);
		color: custom(font_color, #fff);
		font-family: raleway, sans-serif;
		font-size: custom(font_size, 18px);
		font-weight: 700;
		line-height: 48px;
		padding: 0 24px;
		text-transform: uppercase;
		cursor: pointer;
		* + & {
			margin-left: 16px;
		}
	}
}
